import { useRef, useState } from "react";
import {
  UpdateCommentRequest,
  useVbsTreatmentUpdateCommentMutation,
} from "@/services/backend/vbs/treatments/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useTextareaAutoResize } from "@/shared/components/ui/use-textarea-auto-resize";
import { Textarea } from "@/shared/components/ui/textarea";

export function CommentCell(props: {
  treatmentId: string;
  positionId: string;
  comment: string;
}) {
  const { treatmentId, positionId, comment } = props;
  const [request, setRequest] = useState<UpdateCommentRequest>({
    id: treatmentId,
    positionId,
    comment,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsTreatmentUpdateCommentMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  const commentAreaRef = useRef<HTMLTextAreaElement>(null);
  useTextareaAutoResize(commentAreaRef, comment);

  return (
    <Textarea
      className="block min-h-0 w-full px-2 py-0.5 text-sm"
      ref={commentAreaRef}
      rows={3}
      value={request.comment}
      onChange={(e) => setRequest({ ...request, comment: e.target.value })}
    />
  );
}

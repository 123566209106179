import { ProcessAddress } from "@/services/backend/addresses/processaddress/process-address";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";
import t from "@/lang/lang";
import { useState } from "react";
import {
  useAddressesProcessAddressPutMutation,
  useAddressesProcessAddressShowQuery,
} from "@/services/backend/addresses/processaddress/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Address } from "@/services/backend/addresses/address/address";

interface CustomerAddressCardProps {
  customerAddress: Address;
  onCustomerAddressChange: (customerAddress: Address) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  immutable?: boolean;
}

export function CustomerAddressCard({
  customerAddress,
  onCustomerAddressChange,
  fieldErrors = undefined,
  resetFieldError = undefined,
  title = t("Kundenadresse"),
  description = t("Adresse des Kunden"),
  immutable = false,
}: CustomerAddressCardProps) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? fieldErrors[field] ?? [] : [];
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4 sm:grid-cols-2">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="company">{t("Firma")}</Label>
          <Input
            type="text"
            id="company"
            className={
              errorMessages("company").length > 0 ? "border-red-500" : ""
            }
            placeholder={t("Firma")}
            value={customerAddress.company}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("company");
              }
              onCustomerAddressChange({
                ...customerAddress,
                company: e.target.value,
              });
            }}
            disabled={immutable}
          />
          <InputErrorMessages errors={errorMessages("company")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="department">{t("Abteilung")}</Label>
          <Input
            type="text"
            id="department"
            className={
              errorMessages("department").length > 0 ? "border-red-500" : ""
            }
            placeholder={t("Abteilung")}
            value={customerAddress.department}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("department");
              }
              onCustomerAddressChange({
                ...customerAddress,
                department: e.target.value,
              });
            }}
            disabled={immutable}
          />
          <InputErrorMessages errors={errorMessages("department")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="contact">{t("Ansprechpartner")}</Label>
          <Input
            type="text"
            id="contact"
            className={
              errorMessages("contact").length > 0 ? "border-red-500" : ""
            }
            placeholder={t("Ansprechpartner")}
            value={customerAddress.contact}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("contact");
              }
              onCustomerAddressChange({
                ...customerAddress,
                contact: e.target.value,
              });
            }}
            disabled={immutable}
          />
          <InputErrorMessages errors={errorMessages("contact")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="street">{t("Straße")}</Label>
          <Input
            type="text"
            id="street"
            className={
              errorMessages("street").length > 0 ? "border-red-500" : ""
            }
            placeholder={t("Straße")}
            value={customerAddress.street}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("street");
              }
              onCustomerAddressChange({
                ...customerAddress,
                street: e.target.value,
              });
            }}
            disabled={immutable}
          />
          <InputErrorMessages errors={errorMessages("street")} />
        </div>
        <div className="flex space-x-4">
          <div className="grid max-w-xs items-center gap-1.5">
            <Label htmlFor="postalCode">{t("PLZ")}</Label>
            <Input
              type="text"
              id="postalCode"
              className={
                errorMessages("postalCode").length > 0 ? "border-red-500" : ""
              }
              placeholder={t("PLZ")}
              value={customerAddress.postalCode}
              onChange={(e) => {
                if (resetFieldError) {
                  resetFieldError("postalCode");
                }
                onCustomerAddressChange({
                  ...customerAddress,
                  postalCode: e.target.value,
                });
              }}
              disabled={immutable}
            />
            <InputErrorMessages errors={errorMessages("postalCode")} />
          </div>
          <div className="grid w-full items-center gap-1.5">
            <Label htmlFor="city">{t("Stadt")}</Label>
            <Input
              type="text"
              id="city"
              className={
                errorMessages("city").length > 0 ? "border-red-500" : ""
              }
              placeholder={t("Stadt")}
              value={customerAddress.city}
              onChange={(e) => {
                if (resetFieldError) {
                  resetFieldError("city");
                }
                onCustomerAddressChange({
                  ...customerAddress,
                  city: e.target.value,
                });
              }}
              disabled={immutable}
            />
            <InputErrorMessages errors={errorMessages("city")} />
          </div>
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="country">{t("Land")}</Label>
          <Input
            type="text"
            id="country"
            className={
              errorMessages("country").length > 0 ? "border-red-500" : ""
            }
            placeholder={t("Land")}
            value={customerAddress.country}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("country");
              }
              onCustomerAddressChange({
                ...customerAddress,
                country: e.target.value,
              });
            }}
            disabled={immutable}
          />
          <InputErrorMessages errors={errorMessages("country")} />
        </div>
      </CardContent>
    </Card>
  );
}

export function ProcessCustomerAddressCardById({
  id,
  title = t("Kundenadresse"),
  description = t("Adresse des Kunden"),
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  id: string;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const {
    data: customerAddress,
    isLoading,
    error,
  } = useAddressesProcessAddressShowQuery({
    id,
  });

  if (isLoading) {
    return <Skeleton title={title!} description={description!} />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ProcessCustomerAddressCard
      customerAddress={customerAddress!}
      title={title}
      description={description}
      fieldErrors={fieldErrors}
      resetFieldError={resetFieldError}
    />
  );
}

export function ProcessCustomerAddressCard({
  customerAddress,
  title = t("Kundenadresse"),
  description = t("Adresse des Kunden"),
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  customerAddress: ProcessAddress;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const [request, setRequest] = useState<ProcessAddress>({
    ...customerAddress,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useAddressesProcessAddressPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] };

  return (
    <CustomerAddressCard
      customerAddress={request}
      onCustomerAddressChange={(address) =>
        setRequest({
          ...request,
          ...address,
        })
      }
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
      immutable={customerAddress.immutable}
    />
  );
}

function Skeleton(props: { title: string; description: string }) {
  const { title, description } = props;
  return (
    <Card className="animate-pulse">
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
    </Card>
  );
}

import { ProcessContact } from "@/services/backend/contacts/processcontact/process-contact";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  useContactsProcessContactPutMutation,
  useContactsProcessContactShowQuery,
} from "@/services/backend/contacts/processcontact/service";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import { fieldErrors as validationErrors } from "@/shared/app-lib/errors/validation-error";

export function ServiceContactCard({
  serviceContact,
  title = "Service Kontakt",
  description = "Kontakt des Ansprechpartners für den Service.",
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  serviceContact: ProcessContact;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const [request, setRequest] = useState<ProcessContact>({
    ...serviceContact,
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    useContactsProcessContactPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    const externalErrorMessages = fieldErrors ? fieldErrors[field] ?? [] : [];
    const internalErrorMessages = validationErrors(error, field) ?? [];

    return [...externalErrorMessages, ...internalErrorMessages];
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <div className="flex items-start space-x-4">
          <a href={`mailto:${serviceContact.email}`} rel="noreferrer">
            <Button variant="outline">{t("E-Mail")}</Button>
          </a>
          <a href={`mailto:${serviceContact.mobilephone}`} rel="noreferrer">
            <Button variant="outline">{t("Anrufen")}</Button>
          </a>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">{t("Name")}</Label>
          <Input
            type="text"
            id="name"
            placeholder={t("Name")}
            value={request.name}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("name");
              }
              setRequest({ ...request, name: e.target.value });
            }}
          />
          <InputErrorMessages errors={errorMessages("name")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="mobilephone">{t("Mobil")}</Label>
          <Input
            type="text"
            id="mobilephone"
            placeholder={t("Mobil")}
            value={request.mobilephone}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("mobilephone");
              }
              setRequest({ ...request, mobilephone: e.target.value });
            }}
          />
          <InputErrorMessages errors={errorMessages("mobilephone")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="email">{t("E-Mail")}</Label>
          <Input
            type="text"
            id="E-Mail"
            placeholder={t("E-Mail")}
            value={request.email}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("email");
              }
              setRequest({ ...request, email: e.target.value });
            }}
          />
          <InputErrorMessages errors={errorMessages("email")} />
        </div>
      </CardContent>
    </Card>
  );
}

export function ServiceContactCardById({
  id,
  title = "Service Kontakt",
  description = "Kontakt des Ansprechpartners für den Service.",
  fieldErrors = undefined,
  resetFieldError = undefined,
}: {
  id: string;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
}) {
  const {
    data: serviceContact,
    isLoading,
    error,
  } = useContactsProcessContactShowQuery({
    id,
  });

  if (isLoading) {
    return <Skeleton title={title!} description={description!} />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ServiceContactCard
      serviceContact={serviceContact!}
      title={title}
      description={description}
      fieldErrors={fieldErrors}
      resetFieldError={resetFieldError}
    />
  );
}

function Skeleton(props: { title: string; description: string }) {
  const { title, description } = props;
  return (
    <Card className="animate-pulse">
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
    </Card>
  );
}

import {
  ArtefactActions,
  ArtefactActionsDownload,
  ArtefactActionsHeader,
  ArtefactImmutablePage,
  ArtefactPreview,
} from "@/shared/service-manager/artefact/artefact-immutable-page";
import t from "@/lang/lang";
import { ArtefactImmutableAlert } from "@/shared/service-manager/artefact/artefact";
import { Separator } from "@/shared/components/ui/separator";
import { Button } from "@/shared/components/ui/button";
import { Download } from "lucide-react";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { useToast } from "@/shared/components/ui/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { WorkOrder } from "@/services/backend/rlt/work-orders/work-order";
import {
  useRltWorkOrderDownloadPDFMutation,
  useRltWorkOrderPreviewPDFQuery,
  WorkOrderDocument,
} from "@/services/backend/rlt/work-orders/service";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function WorkOrderImmutablePage({
  workOrder,
}: {
  workOrder: WorkOrder;
}) {
  const { data, isLoading, error } = useRltWorkOrderPreviewPDFQuery({
    id: workOrder.id,
    document: WorkOrderDocument.All,
  });

  return (
    <ArtefactImmutablePage>
      <ArtefactPreview
        objectURL={data?.objectURL}
        isLoading={isLoading}
        error={error}
      />
      <ArtefactActions>
        <ArtefactActionsHeader
          title={t("Arbeitsschein")}
          artefactNumber={<ArtefactNumberById artefactId={workOrder.id} />}
        />
        <ArtefactImmutableAlert artefact={workOrder} />
        <Separator />
        <ArtefactActionsDownload>
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.All}
            text={t("Alle Dokumente")}
          />
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.WorkOrder}
            text={t("Arbeitsschein")}
          />
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.Documentation}
            text={t("Dokumentation")}
          />
          <DownloadButton
            workOrderId={workOrder.id}
            document={WorkOrderDocument.LastMinuteRiskAnalysis}
            text={t("LMRA")}
          />
        </ArtefactActionsDownload>
      </ArtefactActions>
    </ArtefactImmutablePage>
  );
}

export function DownloadButton({
  workOrderId,
  document: documentType,
  text,
}: {
  workOrderId: string;
  document: WorkOrderDocument;
  text: string;
}) {
  const [download, { isLoading, error, data, reset }] =
    useRltWorkOrderDownloadPDFMutation();
  const { user } = useAuth();
  const { toast } = useToast();

  useDownloadPdf(data, reset);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [toast, error]);

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: workOrderId,
      document: documentType,
      userId: user?.id ?? "",
    });
  };

  return (
    <Button variant="outline" className="justify-start" onClick={doDownload}>
      {isLoading ? (
        <Download className="mr-4 h-5 w-5 animate-bounce" />
      ) : (
        <Download className="mr-4 h-5 w-5" />
      )}
      <span>{text}</span>
    </Button>
  );
}

import { useParams } from "react-router-dom";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ProcessRepresentativeCardById,
  RepresentativeCardSkeleton,
} from "@/shared/components/domain/representatives/representative-card";
import { useVbsOfferShowQuery } from "@/services/backend/vbs/offers/service";
import {
  filterErrorsWithPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function OfferRepresentativeRoute() {
  const { offerId } = useParams();

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const {
    data: offer,
    isLoading,
    error,
  } = useVbsOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RepresentativeCardSkeleton />;
  }

  return (
    <ProcessRepresentativeCardById
      id={offer!.representativeId}
      fieldErrors={
        filterErrorsWithPrefix(notReadyError, "representative.")?.errors
      }
      resetFieldError={(field) =>
        resetNotReadyErrorField(`representative.${field}`)
      }
    />
  );
}

import {
  ArtefactActions,
  ArtefactActionsDownload,
  ArtefactActionsHeader,
  ArtefactImmutablePage,
  ArtefactPreview,
} from "@/shared/service-manager/artefact/artefact-immutable-page";
import t from "@/lang/lang";
import { ArtefactImmutableAlert } from "@/shared/service-manager/artefact/artefact";
import { Separator } from "@/shared/components/ui/separator";
import { Button } from "@/shared/components/ui/button";
import { Download } from "lucide-react";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { useToast } from "@/shared/components/ui/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Certificate } from "@/services/backend/rlt/certificates/certificate";
import {
  useRltCertificateDownloadPDFMutation,
  useRltCertificatePreviewPDFQuery,
} from "@/services/backend/rlt/certificates/service";

export function CertificateImmutablePage({
  certificate,
}: {
  certificate: Certificate;
}) {
  const { data, isLoading, error } = useRltCertificatePreviewPDFQuery({
    id: certificate.id,
  });

  return (
    <ArtefactImmutablePage>
      <ArtefactPreview
        objectURL={data?.objectURL}
        isLoading={isLoading}
        error={error}
      />
      <ArtefactActions>
        <ArtefactActionsHeader title={t("Zertifikat")} artefactNumber={null} />
        <ArtefactImmutableAlert artefact={certificate} />
        <Separator />
        <ArtefactActionsDownload>
          <DownloadButton certificateId={certificate.id} />
        </ArtefactActionsDownload>
      </ArtefactActions>
    </ArtefactImmutablePage>
  );
}

function DownloadButton({ certificateId }: { certificateId: string }) {
  const [download, { isLoading, error, data, reset }] =
    useRltCertificateDownloadPDFMutation();
  const { user } = useAuth();
  const { toast } = useToast();

  useDownloadPdf(data, reset);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [toast, error]);

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: certificateId,
      downloadBy: user?.id ?? "",
    });
  };

  return (
    <Button variant="outline" className="justify-start" onClick={doDownload}>
      {isLoading ? (
        <Download className="mr-4 h-5 w-5 animate-bounce" />
      ) : (
        <Download className="mr-4 h-5 w-5" />
      )}
      <span>{t("Zertifikat")}</span>
    </Button>
  );
}

import { useCallback, useEffect, useState } from "react";
import {
  AreaMonitor,
  DeleteRequirementMonitorRequest,
  SelectMonitorRequest,
  UpdateRequirementMonitorRequest,
} from "@/services/backend/qpm/requirements/types";
import {
  useQpmRequirementAreaDeleteMutation,
  useQpmRequirementSelectedMonitorAddMutation,
  useQpmRequirementSelectedMonitorDeleteMutation,
  useQpmRequirementSelectedMonitorUpdateMutation,
} from "@/services/backend/qpm/requirements/service";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Button } from "@/shared/components/ui/button";
import { Loader2, Trash2 } from "lucide-react";
import { MonitorRecordList } from "@/services/backend/qpm/monitors/types";
import { useToast } from "@/shared/components/ui/use-toast";
import t from "@/lang/lang";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "@/shared/components/ui/command";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/shared/components/ui/toggle-group";
import { Input } from "@/shared/components/ui/input";
import { Textarea } from "@/shared/components/ui/textarea";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/shared/components/ui/alert-dialog";

export type InventoryComponentProps = {
  requirementId: string;
  areaIndex: number;
  areaId: string;
  monitors: MonitorRecordList;
  selectedMonitors: AreaMonitor[];
};

export function MonitoringComponent({
  requirementId,
  areaIndex,
  areaId,
  monitors,
  selectedMonitors: initialSelectedMonitors,
}: InventoryComponentProps) {
  const { toast } = useToast();
  const [availableMonitors, setAvailableMonitors] = useState(
    () =>
      monitors.monitors.filter(
        (monitor) =>
          !initialSelectedMonitors.find((sm) => sm.id === monitor.id),
      ) || [],
  );
  const [selectedMonitors, setSelectedMonitors] = useState<AreaMonitor[]>(
    initialSelectedMonitors,
  );

  const [
    deleteRequirementArea,
    {
      error: errorDeleteReqArea,
      reset: resetDeleteReqArea,
      isLoading: isLoadingDeleteArea,
    },
  ] = useQpmRequirementAreaDeleteMutation();

  const [
    addSelectedMonitor,
    { error: errorAddSelectedMonitor, reset: resetAddSelectedMonitor },
  ] = useQpmRequirementSelectedMonitorAddMutation();

  // const [
  //   addRequirementPest,
  //   { error: errorAddRequirementPest, reset: resetAddRequirementPest },
  // ] = useQpmRequirementPestAddMutation();

  const getLastDigit = (position: string) => {
    const parts = position.split(".");
    return parseInt(parts[parts.length - 1], 10);
  };

  const getNextMonitorPosition = useCallback(() => {
    if (initialSelectedMonitors.length === 0) {
      return 1;
    }
    return (
      Math.max(
        ...initialSelectedMonitors.map((m) => getLastDigit(m.position)),
        1,
      ) + 1
    );
  }, [initialSelectedMonitors]);

  useEffect(() => {
    if (errorAddSelectedMonitor) {
      toast({ ...parseRTKQueryError(errorAddSelectedMonitor) });
      resetAddSelectedMonitor();
    }
  }, [errorAddSelectedMonitor, resetAddSelectedMonitor, toast]);

  useEffect(() => {
    if (errorDeleteReqArea) {
      toast({ ...parseRTKQueryError(errorDeleteReqArea) });
      resetDeleteReqArea();
    }
  }, [errorDeleteReqArea, resetDeleteReqArea, toast]);

  useEffect(() => {
    setAvailableMonitors(
      monitors.monitors.filter(
        (monitor) =>
          !initialSelectedMonitors.find((sm) => sm.id === monitor.id),
      ),
    );
    setSelectedMonitors(initialSelectedMonitors);
  }, [monitors, initialSelectedMonitors, areaId]);

  const handleMonitorSelect = useCallback(
    (monitorId: string) => {
      const monitor = availableMonitors.find((m) => m.id === monitorId);
      if (monitor) {
        const nextMonitorPosition = getNextMonitorPosition();
        const request: SelectMonitorRequest = {
          requirementId: requirementId!,
          areaId,
          monitorId: monitor.id,
          amount: 1,
          comment: "",
          position: `${areaIndex}.${nextMonitorPosition}`,
        };
        addSelectedMonitor(request);
      }
    },
    [
      addSelectedMonitor,
      areaId,
      areaIndex,
      availableMonitors,
      getNextMonitorPosition,
      requirementId,
    ],
  );

  const handleDeleteArea = () => {
    if (selectedMonitors.length === 0) {
      deleteRequirementArea({ requirementId, areaId });
    }
  };

  return (
    <PersistenceContextProvider>
      <Table className="mb-2">
        <TableHeader>
          <TableRow>
            <TableHead>POS</TableHead>
            <TableHead>Monitor</TableHead>
            <TableHead>Typ</TableHead>
            <TableHead className="text-right">{t("Anzahl")}</TableHead>
            <TableHead className="text-right">
              {t("davon Altbestand")}
            </TableHead>
            <TableHead className="w-2/5">{t("Bemerkung")}</TableHead>
            <TableHead className="w-fit text-right">{t("Aktion")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {selectedMonitors.map((sm) => (
            <RequirementMonitorRow
              key={sm.id}
              sm={sm}
              requirementId={requirementId}
              areaId={areaId}
            />
          ))}
        </TableBody>
      </Table>
      <div className="flex flex-col justify-between px-2 py-4 md:flex-row">
        <MonitorSelectSearchComponent
          monitorList={{ monitors: availableMonitors }}
          onMonitorSelect={handleMonitorSelect}
        />
        <div className="flex justify-end gap-2">
          {isLoadingDeleteArea && <Loader2 className="animate-spin" />}
          {selectedMonitors.length <= 0 && !isLoadingDeleteArea && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button className="gap-2" variant="destructive">
                  <Trash2 className="h-5 w-5" />
                  {t("Bereich entfernen")}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>{t("Bereich entfernen?")}</AlertDialogTitle>
                  <AlertDialogDescription>
                    {t(
                      `Möchten Sie den Bereich aus der Bestandsaufahme entfernen?`,
                    )}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>{t("Abbruch")}</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={handleDeleteArea}
                    className="bg-destructive hover:bg-destructive/90"
                  >
                    {t("Bestätigen")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
          <PersistenceStateUsingContext />
        </div>
      </div>
    </PersistenceContextProvider>
  );
}

export type RequirementMonitorRowProps = {
  sm: AreaMonitor;
  requirementId: string;
  areaId: string;
};

export function RequirementMonitorRow({
  sm,
  areaId,
  requirementId,
}: RequirementMonitorRowProps) {
  const { toast } = useToast();
  const [updateRequest, setUpdateRequest] =
    useState<UpdateRequirementMonitorRequest>({
      requirementId,
      areaId,
      monitorId: sm.id,
      amount: sm.amount,
      existingStockAmount: sm.existingStockAmount,
      comment: sm.comment,
    });

  const [
    updateSelectedMonitor,
    {
      error: errorUpdatedMonitor,
      reset: resetUpdateMonitor,
      isLoading: isLoadingUpdateMonitor,
      isSuccess: isSuccessUpdateMonitor,
    },
  ] = useQpmRequirementSelectedMonitorUpdateMutation();

  const [
    deleteSelectedMonitor,
    {
      error: errorDeleteMonitor,
      reset: resetDeleteSelectedMonitor,
      isLoading: isLoadingDeleteMonitor,
    },
  ] = useQpmRequirementSelectedMonitorDeleteMutation();

  useEffect(() => {
    if (errorDeleteMonitor) {
      toast({ ...parseRTKQueryError(errorDeleteMonitor) });
      resetDeleteSelectedMonitor();
    }
  }, [errorDeleteMonitor, resetDeleteSelectedMonitor, toast]);

  const handleChange = useCallback(
    ({
      monitor,
      amount,
      existingStockAmount,
      comment,
    }: {
      monitor: AreaMonitor;
      amount?: number;
      existingStockAmount?: number;
      comment?: string;
    }) => {
      const request: UpdateRequirementMonitorRequest = {
        ...updateRequest,
        amount: amount !== undefined ? amount : monitor.amount,
        existingStockAmount:
          existingStockAmount !== undefined && existingStockAmount >= 0
            ? existingStockAmount
            : monitor.existingStockAmount,
        comment: comment || monitor.comment,
      };
      setUpdateRequest(request);
    },
    [updateRequest],
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    updateRequest,
    updateSelectedMonitor,
    isLoadingUpdateMonitor,
    errorUpdatedMonitor,
    isSuccessUpdateMonitor,
    resetUpdateMonitor,
    250,
  );

  const handleMonitorDelete = useCallback(
    (monitor: AreaMonitor) => {
      const request: DeleteRequirementMonitorRequest = {
        requirementId,
        areaId,
        monitorId: monitor.id,
      };
      deleteSelectedMonitor(request);
    },
    [areaId, deleteSelectedMonitor, requirementId],
  );

  return (
    <TableRow>
      <TableCell className="py-1 font-bold">{sm.position}</TableCell>
      <TableCell className="py-1">{sm.name}</TableCell>
      <TableCell className="py-1">{sm.type.name}</TableCell>
      <TableCell className="py-1 text-right">
        <div className="flex justify-end">
          <Input
            id={`${sm.id}_main`}
            className="h-8 w-24 text-end"
            type="number"
            min={0}
            defaultValue={sm.amount}
            onChange={(e) =>
              handleChange({
                monitor: sm,
                amount: parseInt(e.target.value, 10),
              })
            }
          />
        </div>
      </TableCell>
      <TableCell className="py-1 text-right">
        <div className="flex justify-end">
          <Input
            id={`${sm.id}_part`}
            className="h-8 w-24 text-end"
            type="number"
            min={0}
            max={sm.amount}
            defaultValue={sm.existingStockAmount || 0}
            onChange={(e) =>
              handleChange({
                monitor: sm,
                existingStockAmount: parseInt(e.target.value, 10),
              })
            }
          />
        </div>
      </TableCell>
      <TableCell className="py-1">
        <Textarea
          className="min-h-8 w-full p-1"
          defaultValue={sm.comment}
          onChange={(e) =>
            handleChange({ monitor: sm, comment: e.target.value })
          }
          placeholder={t("Bemerkung")}
        />
      </TableCell>
      <TableCell className="w-fit text-right">
        {isLoadingDeleteMonitor && <Loader2 className="animate-spin" />}
        {!isLoadingDeleteMonitor && (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="destructive" size="icon">
                <Trash2 className="h-4 w-4" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {t("Monitor aus dem Bereich entfernen?")}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {t(
                    `Sind sie sich sicher, dass sie ${sm.amount} ${sm.name} entfernen möchten?`,
                  )}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>{t("Abbruch")}</AlertDialogCancel>
                <AlertDialogAction
                  onClick={() => handleMonitorDelete(sm)}
                  className="bg-destructive hover:bg-destructive/90"
                >
                  {t("Bestätigen")}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </TableCell>
    </TableRow>
  );
}

export type MonitorSelectComponentProps = {
  monitorList: MonitorRecordList;
  onMonitorSelect: (monitorId: string) => void;
};

export function MonitorSelectSearchComponent({
  monitorList,
  onMonitorSelect,
}: MonitorSelectComponentProps) {
  const monitorTypes = [
    MonitorTypeKey.CheckPoint,
    MonitorTypeKey.FlyExterminator,
    MonitorTypeKey.ALoRa,
  ];
  const [open, setOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState<MonitorTypeKey | null>(null);

  function toggle(monitorTypeKey: MonitorTypeKey) {
    setActiveFilter((prev) =>
      prev === monitorTypeKey ? null : monitorTypeKey,
    );
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[250px] justify-between"
        >
          {t("Monitor auswählen")}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0">
        <Command className="max-h-72">
          <CommandInput placeholder={t("Monitor suchen...")} className="h-9" />
          <ToggleGroup
            type="single"
            size="sm"
            className="items-start justify-start p-1"
            value={activeFilter || undefined}
            onValueChange={(value) => toggle(value as MonitorTypeKey)}
          >
            {monitorTypes.map((key) => (
              <ToggleGroupItem
                key={`${key}_toggle-item`}
                value={key}
                variant="outline"
                aria-label={`${t("Toggle")} ${t(key)}`}
                className="h-fit p-1 text-xs"
              >
                {key}
              </ToggleGroupItem>
            ))}
          </ToggleGroup>
          <ScrollArea className="w-full overflow-auto overscroll-y-contain rounded-md border">
            {monitorTypes.map(
              (key, index) =>
                (!activeFilter || activeFilter === key) && (
                  <div key={key}>
                    <CommandGroup key={`${key}_command-group`} heading={t(key)}>
                      {monitorList &&
                        monitorList.monitors
                          .filter((monitor) => monitor.type.name === key)
                          .map((monitor) => (
                            <CommandItem
                              key={monitor.id}
                              value={monitor.name}
                              onSelect={() => {
                                setOpen(false);
                                onMonitorSelect(monitor.id);
                              }}
                            >
                              {monitor.name}
                            </CommandItem>
                          ))}
                    </CommandGroup>
                    {index !== monitorTypes.length - 1 && (
                      <CommandSeparator key={`${key}_command-separator`} />
                    )}
                  </div>
                ),
            )}
            <CommandEmpty>{t("Monitor nicht vorhanden.")}</CommandEmpty>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  BadgeEuro,
  Calculator,
  Camera,
  Clipboard,
  ClipboardCheck,
  FileInput,
  FileOutput,
  ListChecks,
  ShieldCheck,
} from "lucide-react";
import { cn } from "@/shared/lib/utils";

export interface ArtefactIconProps {
  artefactKind: ArtefactKind;
  className?: string;
}

export function ArtefactIcon({
  artefactKind,
  className = "",
}: ArtefactIconProps) {
  switch (artefactKind) {
    case ArtefactKind.Calculation:
      return <Calculator className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Certificate:
      return <ShieldCheck className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Confirmation:
      return <FileOutput className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Contract:
      return <ShieldCheck className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Documentation:
      return <Camera className={cn("h-5 w-5", className)} />;
    case ArtefactKind.LastMinuteRiskAnalysis:
      return <ListChecks className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Offer:
      return <BadgeEuro className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Order:
      return <FileInput className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Requirement:
      return <Clipboard className={cn("h-5 w-5", className)} />;
    case ArtefactKind.Treatment:
      return <Clipboard className={cn("h-5 w-5", className)} />;
    case ArtefactKind.WorkOrder:
      return <ClipboardCheck className={cn("h-5 w-5", className)} />;
    default:
      return <span>{artefactKind}</span>;
  }
}

import { Calculation } from "@/services/backend/rlt/calculations/calculation";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { TabsNavLink, TabsNavList } from "@/shared/components/layout/tabs-nav";
import t from "@/lang/lang";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  useRltCalculationDeleteMutation,
  useRltCalculationShowQuery,
} from "@/services/backend/rlt/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  MilestonePageContent,
  MilestonePageContentMenu,
} from "@/shared/components/layout/milestone-page";
import { useToast } from "@/shared/components/ui/use-toast";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { useEffect } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Card } from "@/shared/components/ui/card";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function CalculationRoute() {
  const { calculationId } = useParams();
  const {
    data: calculation,
    isLoading,
    error,
  } = useRltCalculationShowQuery({ id: calculationId! });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return <CalculationPage calculation={calculation!} />;
}

function CalculationPage({ calculation }: { calculation: Calculation }) {
  return (
    <PersistenceContextProvider>
      <MilestonePageContent>
        <MilestonePageContentMenu>
          <div className="flex items-center space-x-4">
            <TabsNavList>
              <TabsNavLink to="treatment">{t("Aufnahme")}</TabsNavLink>
              <TabsNavLink to="calculation">{t("Kalkulation")}</TabsNavLink>
            </TabsNavList>
            <span className="font-mono text-lg text-gray-900">
              <ArtefactNumberById artefactId={calculation.id} />
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <PersistenceStateUsingContext className="mr-2" />
            <DeleteCalculationDialog calculationId={calculation.id} />
          </div>
        </MilestonePageContentMenu>
        <Outlet />
      </MilestonePageContent>
    </PersistenceContextProvider>
  );
}

function DeleteCalculationDialog(props: { calculationId: string }) {
  const { calculationId } = props;
  const [deleteCalculation, { isLoading, error, isSuccess, reset }] =
    useRltCalculationDeleteMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteCalculation({ id: calculationId });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Kalkulation gelöscht"),
        variant: "success",
      });
      navigate(`..`);
      reset();
    }
  }, [error, isSuccess, navigate, reset, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Kalkulation wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { useEffect, useState } from "react";
import {
  ListCompanyRequest,
  useListCompanyQuery,
} from "@/services/backend/company/company";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import {
  Match,
  PutRequest,
  useDeleteCompanyMatchMutation,
  useListNewUnternehmensgruppeQuery,
  usePutCompanyMatchMutation,
  useShowCompanyMatchQuery,
} from "@/services/backend/dynamics/company-match";
import { H3 } from "@/shared/components/ui/typography";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export function CompanyMatching() {
  const {
    data: companies,
    isLoading,
    error,
  } = useListCompanyQuery({} as ListCompanyRequest);

  if (isLoading) {
    return (
      <div>
        <div className="flex items-start justify-between">
          <H3>{t("Unternehmen")}</H3>
          <PersistenceStateUsingContext />
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("GSM Unternehmen")}</TableHead>
              <TableHead>{t("Dynamics Unternehmen")}</TableHead>
            </TableRow>
          </TableHeader>
        </Table>
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <PersistenceContextProvider>
      <div className="flex items-start justify-between">
        <H3>{t("Unternehmen")}</H3>
        <PersistenceStateUsingContext />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("GSM Unternehmen")}</TableHead>
            <TableHead>{t("Dynamics Unternehmen")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {companies?.companies.map((company) => (
            <TableRow key={company.id}>
              <TableCell>{company.name}</TableCell>
              <TableCell>
                <NewUnternehmensgruppe companyId={company.id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PersistenceContextProvider>
  );
}

function NewUnternehmensgruppe({ companyId }: { companyId: string }) {
  const {
    data: match,
    isLoading,
    error,
  } = useShowCompanyMatchQuery({ companyId });

  if (isLoading) {
    return <DynamicsCompanySelectSkeleton />;
  }

  // 404 is treated as an implicit match, without a newUnternehmensgruppeValue
  if (typeof error === "object" && "status" in error && error.status === 404) {
    return (
      <NewUnternehmensgruppeMatch
        match={{ companyId, newUnternehmensgruppeValue: 0 }}
      />
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <NewUnternehmensgruppeMatch match={match!} />;
}

function NewUnternehmensgruppeMatch({ match }: { match: Match }) {
  const [request, setRequest] = useState<PutRequest>({
    ...match,
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutCompanyMatchMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const [deleteMatch, { isLoading: deleteIsLoading, error: deleteError }] =
    useDeleteCompanyMatchMutation();

  const doDelete = () => {
    if (deleteIsLoading) {
      return;
    }

    deleteMatch({ companyId: match.companyId });
  };

  const { toast } = useToast();

  useEffect(() => {
    if (deleteError) {
      toast({
        ...parseRTKQueryError(deleteError),
        variant: "destructive",
      });
    }
  }, [deleteError, toast]);

  return (
    <DynamicsCompanySelect
      dynamicsCompanyId={match.newUnternehmensgruppeValue.toString(10)}
      onDynamicsCompanyIdChange={(newUnternehmensgruppeValue) => {
        if (newUnternehmensgruppeValue === "0") {
          doDelete();
        } else {
          setRequest({
            ...request,
            newUnternehmensgruppeValue: parseInt(
              newUnternehmensgruppeValue,
              10,
            ),
          });
        }
      }}
      disabled={isLoading || deleteIsLoading}
    />
  );
}

interface DynamicsCompanySelectProps {
  dynamicsCompanyId: string;
  onDynamicsCompanyIdChange: (dynamicsBranchId: string) => void;
  disabled?: boolean;
}

function DynamicsCompanySelect({
  dynamicsCompanyId,
  onDynamicsCompanyIdChange,
  disabled = false,
}: DynamicsCompanySelectProps) {
  const {
    data: dynamicsCompanies,
    isLoading,
    error,
  } = useListNewUnternehmensgruppeQuery({});

  if (isLoading) {
    return <DynamicsCompanySelectSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Select
      disabled={disabled}
      value={dynamicsCompanyId}
      onValueChange={onDynamicsCompanyIdChange}
    >
      <SelectTrigger>
        <SelectValue placeholder={t("Dynamics Niederlassung auswählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {dynamicsCompanies?.newUnternehmensgruppen.map((dynamicsCompany) => (
            <SelectItem
              value={dynamicsCompany.value.toString()}
              key={dynamicsCompany.value}
            >
              {dynamicsCompany.label} ({dynamicsCompany.value})
            </SelectItem>
          ))}
        </SelectGroup>
        <SelectSeparator />
        <SelectItem value="0">{t("Keine Zuordnung")}</SelectItem>
      </SelectContent>
    </Select>
  );
}

function DynamicsCompanySelectSkeleton() {
  return (
    <Select disabled>
      <SelectTrigger className="animate-pulse">
        <SelectValue placeholder={t("Lade...")} />
      </SelectTrigger>
    </Select>
  );
}

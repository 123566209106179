import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router-dom";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Checkbox } from "@/shared/components/ui/checkbox";
import {
  useRltOfferListQuery,
  useRltOfferSetCurrentMutation,
  useRltOfferShowCurrentQuery,
} from "@/services/backend/rlt/offers/service";
import { Offer } from "@/services/backend/rlt/offers/offer";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function OfferTable(props: { processId: string }) {
  const { processId } = props;

  const {
    data: list,
    isLoading,
    error,
  } = useRltOfferListQuery({
    process: {
      active: true,
      values: [processId],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead className="w-24">{t("Aktuelles")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-full">{t("Heruntergeladen")}</TableHead>
            <TableHead className="w-32">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-24">{t("Aktuelle")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Angebotsdatum")}</TableHead>
          <TableHead className="w-full">{t("Heruntergeladen")}</TableHead>
          <TableHead className="w-48">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.offers ?? [])].sort(byOfferDate).map((offer) => (
          <TableRow key={offer.id}>
            <TableCell className="pl-8">
              <CurrentCheckbox offerId={offer.id} processId={processId} />
            </TableCell>
            <TableCell>
              <NavLink to={`${offer.id}`}>
                <ArtefactNumberById artefactId={offer.id} />
              </NavLink>
            </TableCell>
            <TableCell>
              {new Date(offer.offerDate).toLocaleDateString()}
            </TableCell>
            <TableCell>{offer.immutable ? "ja" : "nein"}</TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavLink to={`${offer.id}`}>
                  <Button variant="ghost">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {list?.offers?.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Angebote gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function byOfferDate(a: Offer, b: Offer) {
  return a.offerDate.localeCompare(b.offerDate);
}

function CurrentCheckbox(props: { offerId: string; processId: string }) {
  const { offerId, processId } = props;
  const { data: current } = useRltOfferShowCurrentQuery({ processId });
  const [set, { isLoading, error, reset }] = useRltOfferSetCurrentMutation();
  const { toast } = useToast();

  const onClick = () => {
    if (isLoading) {
      return;
    }

    set({ processId, offerId });
  };

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  if (current?.offerId === offerId) {
    return <Checkbox checked className="h-6 w-6 cursor-default" />;
  }

  return <Checkbox className="h-6 w-6" checked={false} onClick={onClick} />;
}

import { useEffect, useState } from "react";
import { H3 } from "@/shared/components/ui/typography";
import { CompanyName } from "@/shared/components/domain/company/company-name";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  Match,
  PutRequest,
  useDeleteBranchMatchMutation,
  useListAxNiederlassungQuery,
  usePutBranchMatchMutation,
  useShowBranchMatchQuery,
} from "@/services/backend/dynamics/branch-match";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ListBranchRequest,
  useListBranchQuery,
} from "@/services/backend/branch/branch";

export function BranchMatching() {
  const {
    data: branches,
    isLoading,
    error,
  } = useListBranchQuery({} as ListBranchRequest);

  if (isLoading) {
    return (
      <div>
        <div className="flex items-start justify-between">
          <H3>{t("Niederlassungen")}</H3>
          <PersistenceStateUsingContext />
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("GSM Niederlassung")}</TableHead>
              <TableHead>{t("GSM Unternehmen")}</TableHead>
              <TableHead>{t("Dynamics Niederlassung")}</TableHead>
            </TableRow>
          </TableHeader>
        </Table>
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <PersistenceContextProvider>
      <div className="flex items-start justify-between">
        <H3>{t("Niederlassungen")}</H3>
        <PersistenceStateUsingContext />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("GSM Niederlassung")}</TableHead>
            <TableHead>{t("GSM Unternehmen")}</TableHead>
            <TableHead>{t("Dynamics Niederlassung")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {branches?.branches.map((branch) => (
            <TableRow key={branch.id}>
              <TableCell>{branch.name}</TableCell>
              <TableCell>
                <CompanyName companyId={branch.companyId} />
              </TableCell>
              <TableCell>
                <AxNiederlassung branchId={branch.id} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PersistenceContextProvider>
  );
}

function AxNiederlassung({ branchId }: { branchId: string }) {
  const {
    data: match,
    isLoading,
    error,
  } = useShowBranchMatchQuery({ branchId });

  if (isLoading) {
    return <DynamicsBranchSelectSkeleton />;
  }

  // 404 is treated as an implicit match, with axNiederlassungValue = 0 "no match"
  if (typeof error === "object" && "status" in error && error.status === 404) {
    return (
      <AxNiederlassungMatch match={{ branchId, axNiederlassungValue: 0 }} />
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <AxNiederlassungMatch match={match!} />;
}

function AxNiederlassungMatch({ match }: { match: Match }) {
  const [request, setRequest] = useState<PutRequest>({
    ...match,
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutBranchMatchMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const [deleteMatch, { isLoading: deleteIsLoading, error: deleteError }] =
    useDeleteBranchMatchMutation();

  const doDelete = () => {
    if (deleteIsLoading) {
      return;
    }

    deleteMatch({ branchId: match.branchId });
  };

  const { toast } = useToast();

  useEffect(() => {
    if (deleteError) {
      toast({
        ...parseRTKQueryError(deleteError),
        variant: "destructive",
      });
    }
  }, [deleteError, toast]);

  return (
    <DynamicsBranchSelect
      dynamicsBranchId={match.axNiederlassungValue.toString(10)}
      onDynamicsBranchIdChange={(axNiederlassungValue) => {
        if (axNiederlassungValue === "0") {
          doDelete();
        } else {
          setRequest({
            ...request,
            axNiederlassungValue: parseInt(axNiederlassungValue, 10),
          });
        }
      }}
      disabled={isLoading || deleteIsLoading}
    />
  );
}

interface DynamicsBranchSelectProps {
  dynamicsBranchId: string;
  onDynamicsBranchIdChange: (dynamicsBranchId: string) => void;
  disabled?: boolean;
}

function DynamicsBranchSelect({
  dynamicsBranchId,
  onDynamicsBranchIdChange,
  disabled = false,
}: DynamicsBranchSelectProps) {
  const {
    data: dynamicsBranches,
    isLoading,
    error,
  } = useListAxNiederlassungQuery({});

  if (isLoading) {
    return <DynamicsBranchSelectSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Select
      disabled={disabled}
      value={dynamicsBranchId}
      onValueChange={onDynamicsBranchIdChange}
    >
      <SelectTrigger>
        <SelectValue placeholder={t("Dynamics Niederlassung auswählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {dynamicsBranches?.axNiederlassungen.map((dynamicsBranch) => (
            <SelectItem
              value={dynamicsBranch.value.toString()}
              key={dynamicsBranch.value}
            >
              {dynamicsBranch.label} ({dynamicsBranch.value})
            </SelectItem>
          ))}
        </SelectGroup>
        <SelectSeparator />
        <SelectItem value="0">{t("Keine Zuordnung")}</SelectItem>
      </SelectContent>
    </Select>
  );
}

function DynamicsBranchSelectSkeleton() {
  return (
    <Select disabled>
      <SelectTrigger className="animate-pulse">
        <SelectValue placeholder={t("Lade...")} />
      </SelectTrigger>
    </Select>
  );
}

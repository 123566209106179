import { useParams } from "react-router-dom";
import { useContactsDefaultContactListQuery } from "@/services/backend/contacts/default-contact/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { H2, P } from "@/shared/components/ui/typography";
import t from "@/lang/lang";

export default function ContactRoute() {
  const { processId } = useParams();
  const {
    data: contacts,
    isLoading,
    error,
  } = useContactsDefaultContactListQuery({ processId: processId! });

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-4 text-gray-300">
        <H2>{t("Ansprechpartner")}</H2>
        <div className="block max-w-sm rounded-lg border border-gray-200 bg-white p-4 shadow sm:max-w-md">
          <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-300">
            {t("Lade Kontakte")}
          </h5>
          <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
          <div className="mb-2.5 h-2 max-w-[300px] rounded-full bg-gray-200" />
        </div>
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="space-y-4">
      <H2>Ansprechpartner</H2>
      <P className="text-muted-foreground">
        {t(
          "Hier werden alle Ansprechpartner angezeigt welche mit dem Vorgang oder dem Kunden verbunden sind.",
        )}
      </P>
      <div className="grid w-fit grid-cols-1 gap-4 lg:grid-cols-2">
        {contacts?.contacts.map((contact) => (
          <div
            className="block max-w-sm rounded-lg border border-gray-200 bg-white p-4 shadow sm:max-w-md"
            key={contact.id}
          >
            <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
              {contact.name}
            </h5>
            <div className="mb-2 text-sm font-bold text-gray-700">
              {t(contact.contactKind.name)}
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-sm text-gray-600">{t("Funktion")}</span>
              <span className="text-right text-sm font-medium text-gray-700">
                {contact.function}
              </span>
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-sm text-gray-600">{t("Telefon")}</span>
              <a
                href={`tel:${contact.phone}`}
                className="text-right text-sm font-medium text-blue-700 hover:underline"
              >
                {contact.phone}
              </a>
            </div>
            <div className="flex flex-row justify-between">
              <span className="text-sm text-gray-600">{t("Mobil")}</span>
              <a
                href={`tel:${contact.mobilephone}`}
                className="text-right text-sm font-medium text-blue-700 hover:underline"
              >
                {contact.mobilephone}
              </a>
            </div>
            <div className="flex flex-row justify-between space-x-3">
              <span className="text-sm text-gray-600">{t("E-Mail")}</span>
              <a
                href={`tel:${contact.email}`}
                className="text-right text-sm font-medium text-blue-700 hover:underline"
              >
                {contact.email}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

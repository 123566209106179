import { RouteObject } from "react-router-dom";
import { VbsBaseDataRoute } from "@/routes/base-data/processes/vbs/vbs-base-data-route";
import { RltBaseDataRoute } from "@/routes/base-data/processes/rlt/rlt-base-data-route";
import { QpmBaseDataRoute } from "@/routes/base-data/processes/qpm/qpm-base-data-route";
import { vbsBaseDataRoutes } from "@/routes/base-data/processes/vbs/vbs-base-data-routes";
import { rltBaseDataRoutes } from "@/routes/base-data/processes/rlt/rlt-base-data-routes";

export const processesBaseDataRoutes: RouteObject[] = [
  {
    path: "qpm",
    element: <QpmBaseDataRoute />,
  },
  {
    path: "rlt",
    element: <RltBaseDataRoute />,
    children: rltBaseDataRoutes,
  },
  {
    path: "vbs",
    element: <VbsBaseDataRoute />,
    children: vbsBaseDataRoutes,
  },
];

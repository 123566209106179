import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  isValidationError,
  ValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { isFetchBaseQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

/**
 * ErrArtefactNotReady is technically a validation error.
 * However, its scope and meaning extends over simple form validation.
 * The error potentially spreads multiple components which is why
 * its state is saved in a React context.
 */
export interface ErrArtefactNotReady extends ValidationError {}

export function useErrArtefactNotReady() {
  return useContext(ErrArtefactNotReadyContext);
}

/**
 * useSetErrArtefactNotReadyFromRtk is a convenience hook to
 * set the artefact not ready error. It saves the need to
 * write a useEffect hook and related boilerplate code.
 * The cleanUp function is optional and will be called
 * if the error is set.
 *
 * @param error - The error to check and set.
 * @param cleanUp - A function that will be called if the error is set.
 */
export function useSetErrArtefactNotReadyFromRtk(
  error: unknown,
  cleanUp?: () => void,
) {
  const { setNotReadyError } = useErrArtefactNotReady();

  useEffect(() => {
    // attempt to retrieve the error from the rtk error
    const err = fromRtkErr(error);
    if (!err) {
      return;
    }

    setNotReadyError(err);
    if (cleanUp) {
      cleanUp();
    }
  }, [error, cleanUp, setNotReadyError]);
}

interface ErrArtefactNotReadyContextInterface {
  notReadyError: ErrArtefactNotReady | null;
  setNotReadyError: (error: ErrArtefactNotReady | null) => void;
  resetNotReadyErrorField: (field: string) => void;
  resetNotReadyError: () => void;
}

const ErrArtefactNotReadyContext =
  createContext<ErrArtefactNotReadyContextInterface>({
    notReadyError: null,
    setNotReadyError: () => null,
    resetNotReadyErrorField: () => null,
    resetNotReadyError: () => null,
  });

export function ErrArtefactNotReadyContextProvider({
  children,
}: PropsWithChildren) {
  const [notReadyError, setNotReadyError] =
    useState<ErrArtefactNotReady | null>(null);

  const value: ErrArtefactNotReadyContextInterface = useMemo(() => {
    const setNotReadyErrorWithCheck = (error: unknown) => {
      if (!error) {
        return;
      }

      if (!isErrArtefactNotReady(error)) {
        return;
      }

      setNotReadyError(error);
    };

    const resetNotReadyErrorField = (field: string) => {
      if (!notReadyError) {
        return;
      }

      const newError = removeErrorsFor(notReadyError, field);

      // if the last field error was removed, reset the error to null
      if (Object.entries(newError.errors).length === 0) {
        setNotReadyError(null);
        return;
      }

      setNotReadyError(newError);
    };

    const resetNotReadyError = () => {
      setNotReadyError(null);
    };

    return {
      notReadyError,
      setNotReadyError: setNotReadyErrorWithCheck,
      resetNotReadyErrorField,
      resetNotReadyError,
    };
  }, [notReadyError, setNotReadyError]);

  return (
    <ErrArtefactNotReadyContext.Provider value={value}>
      {children}
    </ErrArtefactNotReadyContext.Provider>
  );
}

function fromRtkErr(error: unknown): ErrArtefactNotReady | null {
  if (!isFetchBaseQueryError(error)) {
    return null;
  }

  if (error.status !== 400) {
    return null;
  }

  if (!isErrArtefactNotReady(error.data)) {
    return null;
  }

  return error.data;
}

function isErrArtefactNotReady(error: unknown): error is ErrArtefactNotReady {
  return isValidationError(error);
}

function removeErrorsFor(
  error: ErrArtefactNotReady,
  field: string,
): ErrArtefactNotReady {
  return {
    ...error,
    errors: Object.fromEntries(
      Object.entries(error.errors).filter(([key]) => key !== field),
    ),
  };
}

/**
 * A helper function to adjust UI behaviour based on the error.
 * @param error The error to check.
 */
export function rtkErrIsErrArtefactNotReady(
  error: unknown,
): error is { data: ErrArtefactNotReady } {
  if (!error) {
    return false;
  }

  if (!isFetchBaseQueryError(error)) {
    return false;
  }

  if (error.status !== 400) {
    return false;
  }

  return isErrArtefactNotReady(error.data);
}

export function errorsFor(
  error: ErrArtefactNotReady | null,
  field: string,
): string[] | null {
  return error?.errors[field] ?? null;
}

/**
 * A helper function to remove all errors where the field starts with the given prefix.
 * @param error
 * @param prefix
 */
export function filterErrorsWithPrefix(
  error: ErrArtefactNotReady | null,
  prefix: string,
): ErrArtefactNotReady | null {
  if (!error) {
    return null;
  }

  const newErrors = Object.fromEntries(
    Object.entries(error.errors)
      .filter(([key]) => key.startsWith(prefix))
      .map(([key, value]) => [key.slice(prefix.length), value]),
  );

  return {
    msg: error.msg,
    errors: newErrors,
  };
}

import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import t from "@/lang/lang";
import { v4 } from "uuid";
import { useToast } from "@/shared/components/ui/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useNavigate } from "react-router-dom";
import { useRltConfirmationCreateMutation } from "@/services/backend/rlt/confirmations/service";

interface Props {
  processId: string;
}

export function CreateConfirmationButton({ processId }: Props) {
  const [create, { isLoading, error, data, isSuccess, reset }] =
    useRltConfirmationCreateMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    const id = v4();
    create({ id, processId });
  };

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
    if (isSuccess) {
      navigate(data.id);
      reset();
    }
  }, [data?.id, error, isSuccess, navigate, toast, reset]);

  return (
    <Button onClick={doCreate} disabled={isLoading}>
      <Plus className="mr-2 h-5 w-5" />
      <span>{t("Auftragsbestätigung")}</span>
    </Button>
  );
}

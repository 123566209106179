import { useRoutes } from "react-router-dom";
import ProcessesRoute from "@/routes/gesec/processes/processes-route";
import VBSRoute from "@/routes/gesec/processes/[processId]/vbs/vbs";
import RLTRoute from "@/routes/gesec/processes/[processId]/rlt/rlt";
import { vbsRoutes } from "@/routes/gesec/processes/[processId]/vbs/vbs-routes";
import { rltRoutes } from "@/routes/gesec/processes/[processId]/rlt/rlt-routes";

export default function GesecRouter() {
  return useRoutes([
    {
      path: "processes",
      element: <ProcessesRoute />,
      children: [],
    },
    {
      path: "processes/rlt/:processId",
      element: <RLTRoute />,
      children: rltRoutes,
    },
    {
      path: "processes/vbs/:processId",
      element: <VBSRoute />,
      children: vbsRoutes,
    },
  ]);
}

import {
  CreateLevelRequest,
  Level,
} from "@/services/backend/qpm/buildings/types";
import { useEffect, useState } from "react";
import { useQpmLevelAddMutation } from "@/services/backend/qpm/buildings/service";
import { v4 } from "uuid";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

export type AddLevelComponentProps = {
  buildingId: string;
  callback?: (level: Level) => void;
};

export function AddLevelComponent({
  buildingId,
  callback = undefined,
}: AddLevelComponentProps) {
  const { toast } = useToast();
  const [newLevelName, setNewLevelName] = useState<string>("");
  const [createNewLevel, { error: createLevelError, isSuccess, reset, data }] =
    useQpmLevelAddMutation();

  useEffect(() => {
    if (createLevelError) {
      toast({ ...parseRTKQueryError(createLevelError) });
      reset();
    }
  }, [createLevelError, reset, toast]);

  useEffect(() => {
    if (isSuccess) {
      setNewLevelName("");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data && callback) {
      callback(data);
    }
  }, [callback, data]);

  const handleInputChange = (event: { target: { value: string } }) => {
    const name = event.target.value;
    setNewLevelName(name);
  };

  const handleSubmit = () => {
    if (!newLevelName) {
      return;
    }
    const newLevelRequest: CreateLevelRequest = {
      id: v4(),
      name: newLevelName.trim(),
      buildingId,
    };
    createNewLevel(newLevelRequest);
  };

  return (
    <div className="flex w-fit items-center gap-2">
      <Input
        placeholder="Ebene hinzufügen..."
        value={newLevelName}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
      />
      <Button size="icon" onClick={handleSubmit}>
        <Plus />
      </Button>
    </div>
  );
}

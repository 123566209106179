import { useAuthEntraGroupListQuery } from "@/services/backend/entra/entragroup/service";
import { ColumnDef } from "@tanstack/react-table";
import { Group } from "@/services/backend/entra/entragroup/group";
import t from "@/lang/lang";
import { CompanyName } from "@/shared/components/domain/company/company-name";
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PutEntraGroupDialog } from "@/routes/base-data/entra/entra-groups/components/put-entra-group-dialog";
import { DeleteEntraGroupDialog } from "@/routes/base-data/entra/entra-groups/components/delete-entra-group-dialog";

export function EntraGroupsTable() {
  const { data, isLoading, error } = useAuthEntraGroupListQuery({});

  const table = useDataTableController(columns, data?.groups ?? []);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <DataTableContainer className="animate-pulse">
        <DataTable columns={columns} table={table} />
      </DataTableContainer>
    );
  }

  return (
    <DataTableContainer>
      <DataTable columns={columns} table={table} />
    </DataTableContainer>
  );
}

const columns: ColumnDef<Group>[] = [
  {
    header: t("ID"),
    accessorKey: "id",
  },
  {
    header: t("Name"),
    accessorKey: "name",
  },
  {
    header: t("Unternehmen"),
    cell: ({ row }) => {
      const group = row.original;
      return (
        <div className="flex flex-col">
          {group.companies.map((company) => (
            <CompanyName companyId={company} key={company} />
          ))}
        </div>
      );
    },
  },
  {
    header: t("Aktionen"),
    cell: ({ row }) => {
      const group = row.original;
      return (
        <span className="space-x-2">
          <PutEntraGroupDialog group={group} />
          <DeleteEntraGroupDialog group={group} />
        </span>
      );
    },
  },
];

import { useEffect, useState } from "react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import {
  Match,
  PutRequest,
  useDeleteProcessKindMatchMutation,
  useListAxDienstleistungQuery,
  usePutProcessKindMatchMutation,
  useShowProcessKindMatchQuery,
} from "@/services/backend/dynamics/process-kind-match";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { H3 } from "@/shared/components/ui/typography";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { ProcessKindList } from "@/services/backend/processes/process/kinds";

export function ProcessKindMatching() {
  return (
    <PersistenceContextProvider>
      <div className="flex items-start justify-between">
        <H3>{t("Dienstleistungen")}</H3>
        <PersistenceStateUsingContext />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("GSM Dienstleistung")}</TableHead>
            <TableHead>{t("Dynamics Dienstleistung")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {ProcessKindList.map((processKind) => (
            <TableRow key={processKind}>
              <TableCell>{processKind}</TableCell>
              <TableCell>
                <AxDienstleistung processKind={processKind} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PersistenceContextProvider>
  );
}

function AxDienstleistung({ processKind }: { processKind: string }) {
  const {
    data: match,
    isLoading,
    error,
  } = useShowProcessKindMatchQuery({ processKind });

  if (isLoading) {
    return <DynamicsProcessKindSelectSkeleton />;
  }

  // 404 is treated as an implicit match, without a newUnternehmensgruppeValue
  if (typeof error === "object" && "status" in error && error.status === 404) {
    return (
      <AxDienstleistungMatch
        match={{ processKind, axDienstleistungValue: 0 }}
      />
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <AxDienstleistungMatch match={match!} />;
}

function AxDienstleistungMatch({ match }: { match: Match }) {
  const [request, setRequest] = useState<PutRequest>({
    ...match,
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    usePutProcessKindMatchMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const [deleteMatch, { isLoading: deleteIsLoading, error: deleteError }] =
    useDeleteProcessKindMatchMutation();

  const doDelete = () => {
    if (deleteIsLoading) {
      return;
    }

    deleteMatch({ processKind: match.processKind });
  };

  const { toast } = useToast();

  useEffect(() => {
    if (deleteError) {
      toast({
        ...parseRTKQueryError(deleteError),
        variant: "destructive",
      });
    }
  }, [deleteError, toast]);

  return (
    <DynamicsProcessKindSelect
      dynamicsProcessKindId={match.axDienstleistungValue.toString(10)}
      onDynamicsProcessKindIdChange={(axDienstleistungValue) => {
        if (axDienstleistungValue === "0") {
          doDelete();
        } else {
          setRequest({
            ...request,
            axDienstleistungValue: parseInt(axDienstleistungValue, 10),
          });
        }
      }}
      disabled={isLoading || deleteIsLoading}
    />
  );
}

interface DynamicsProcessKindSelectProps {
  dynamicsProcessKindId: string;
  onDynamicsProcessKindIdChange: (dynamicsBranchId: string) => void;
  disabled?: boolean;
}

function DynamicsProcessKindSelect({
  dynamicsProcessKindId,
  onDynamicsProcessKindIdChange,
  disabled = false,
}: DynamicsProcessKindSelectProps) {
  const {
    data: dynamicsProcessKindes,
    isLoading,
    error,
  } = useListAxDienstleistungQuery({});

  if (isLoading) {
    return <DynamicsProcessKindSelectSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Select
      disabled={disabled}
      value={dynamicsProcessKindId}
      onValueChange={onDynamicsProcessKindIdChange}
    >
      <SelectTrigger>
        <SelectValue placeholder={t("Dynamics Dienstleistung auswählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {dynamicsProcessKindes?.axDienstleistungen.map(
            (dynamicsProcessKind) => (
              <SelectItem
                value={dynamicsProcessKind.value.toString()}
                key={dynamicsProcessKind.value}
              >
                {dynamicsProcessKind.label} ({dynamicsProcessKind.value})
              </SelectItem>
            ),
          )}
        </SelectGroup>
        <SelectSeparator />
        <SelectItem value="0">{t("Keine Zuordnung")}</SelectItem>
      </SelectContent>
    </Select>
  );
}

function DynamicsProcessKindSelectSkeleton() {
  return (
    <Select disabled>
      <SelectTrigger className="animate-pulse">
        <SelectValue placeholder={t("Lade...")} />
      </SelectTrigger>
    </Select>
  );
}

import { HTMLAttributes } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import t from "../../../../lang/lang";

interface RTKQueryErrorAlertProps extends HTMLAttributes<HTMLDivElement> {
  error: unknown;
}

export function RTKQueryErrorAlert({
  error,
  className,
  ...props
}: RTKQueryErrorAlertProps) {
  if (error === undefined || error === null) {
    return null;
  }

  // default values
  let title = t("Fehler");
  let description = t("Ein Fehler ist aufgetreten.");

  const err = parseRTKQueryError(error);
  if (err == null) {
    return null;
  }
  title = err.title;
  description = err.description;

  return (
    <Alert variant="destructive" className={className} {...props}>
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
    </Alert>
  );
}

import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import { v4 } from "uuid";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useRltDocumentationAddItemMutation } from "@/services/backend/rlt/work-orders/documentation-service";
import { useDocumentationPath } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/documentation/[documentationId]/_components/use-documentation-path";

export function AddItemButton({
  documentationId,
  workOrderId,
}: {
  documentationId: string;
  workOrderId: string;
}) {
  const [add, { isLoading, error, isSuccess, reset }] =
    useRltDocumentationAddItemMutation();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
    }
  }, [isSuccess, error, reset, toast]);

  const docuPath = useDocumentationPath(workOrderId, documentationId);
  if (docuPath === "") {
    return null;
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }
    const newID = v4();
    add({
      id: documentationId,
      itemId: newID,
    })
      .unwrap()
      .then(() => {
        navigate(`${docuPath}/${newID}`);
      });
  };

  return (
    <Button
      className="flex items-center space-x-2"
      onClick={onClick}
      disabled={isLoading}
    >
      <Plus />
    </Button>
  );
}

import { useEffect, useState } from "react";
import {
  AreaList,
  CreateLevelRequest,
  Level,
} from "@/services/backend/qpm/buildings/types";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import {
  ChevronDown,
  ChevronRight,
  Loader2,
  Pencil,
  Save,
  Trash2,
  X,
} from "lucide-react";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import {
  useQpmLevelAddMutation,
  useQpmLevelDeleteMutation,
} from "@/services/backend/qpm/buildings/service";
import { AreaSegmentCollapsible } from "@/routes/gemex/processes/qpm/building/_components/area-segment-collapsible";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import t from "@/lang/lang";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/shared/components/ui/alert-dialog";
import { NewAreaPopOverButton } from "@/routes/gemex/processes/qpm/building/_components/new-area-popover-button";

type LevelSegmentCollapsibleProps = {
  id: string;
  buildingId: string;
  level: Level;
  isOpen?: boolean;
};

export function LevelSegmentCollapsible({
  id,
  buildingId,
  level,
  isOpen = true,
}: LevelSegmentCollapsibleProps) {
  const { toast } = useToast();
  const [open, setOpen] = useState(isOpen === undefined ? true : isOpen);
  const [editModeActive, setEditMode] = useState(!level);
  const [displayedLevel, setDisplayedLevel] = useState<Level>(level);
  const [levelName, setLevelName] = useState<string>("");
  const [availableAreas, setAvailableAreas] = useState<AreaList>([]);
  const [
    createOrUpdateLevel,
    {
      data,
      error: createLevelError,
      reset: resetCreateLevel,
      isLoading: isLoadingSaveLevel,
    },
  ] = useQpmLevelAddMutation();

  const [
    deleteLevel,
    {
      error: errorDeleteLevel,
      reset: resetDeleteLevel,
      isLoading: isLoadingDelete,
    },
  ] = useQpmLevelDeleteMutation();

  useEffect(() => {
    setAvailableAreas(level.base.children);
  }, [level.base.children]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleLevelNameChange = (event: { target: { value: string } }) => {
    setLevelName(event.target.value);
  };

  useEffect(() => {
    if (createLevelError) {
      toast({ ...parseRTKQueryError(createLevelError) });
      resetCreateLevel();
    }
  }, [createLevelError, resetCreateLevel, toast]);

  useEffect(() => {
    if (errorDeleteLevel) {
      toast({ ...parseRTKQueryError(errorDeleteLevel) });
      resetDeleteLevel();
    }
  }, [errorDeleteLevel, resetDeleteLevel, toast]);

  useEffect(() => {
    if (data) {
      setDisplayedLevel(data);
      setEditMode(false);
    }
  }, [data]);

  const handleSave = () => {
    if (!levelName) {
      return;
    }
    const createLevelRequest: CreateLevelRequest = {
      id,
      name: levelName.trim(),
      buildingId,
    };
    createOrUpdateLevel(createLevelRequest);
  };

  const handleDelete = () => {
    if (!isLoadingDelete) {
      deleteLevel({ id: level.base.id });
    }
  };

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="overflow-auto-x">
        <div className="flex bg-muted">
          <div className="flex w-full">
            <CollapsibleTrigger>
              <div className="flex items-center text-sm font-bold uppercase">
                <div className="py-3 pl-6">
                  {open ? (
                    <ChevronDown className="h-5 w-5" />
                  ) : (
                    <ChevronRight className="h-5 w-5" />
                  )}
                </div>
                {!editModeActive && (
                  <div className="text-nowrap py-3 pl-3 pr-6">
                    <span>{displayedLevel?.base.name}</span>
                  </div>
                )}
              </div>
            </CollapsibleTrigger>
            <div className="flex w-full items-center justify-between">
              <div className="py-3 pl-3 pr-6">
                {editModeActive && (
                  <Input
                    placeholder={t("Ebenenname")}
                    defaultValue={displayedLevel?.base.name}
                    readOnly={!editModeActive}
                    onChange={handleLevelNameChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSave();
                      }
                    }}
                  />
                )}
              </div>
              <div className="flex items-center space-x-2 p-2">
                {!editModeActive && (
                  <>
                    <NewAreaPopOverButton level={level} />
                    <Button size="icon" variant="outline" onClick={handleEdit}>
                      <Pencil className="h-5 w-5" />
                    </Button>
                  </>
                )}
                {editModeActive && (
                  <>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            size="icon"
                            onClick={handleSave}
                            disabled={!levelName || isLoadingSaveLevel}
                          >
                            <Save />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t("Speichern")}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>

                    {isLoadingDelete && <Loader2 className="animate-sping" />}
                    {(!level.base.children ||
                      level.base.children.length === 0) &&
                      !isLoadingDelete && (
                        <AlertDialog>
                          <AlertDialogTrigger asChild>
                            <Button size="icon" variant="destructive">
                              <Trash2 className="h-5 w-5" />
                            </Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>
                                {t("Ebene löschen?")}
                              </AlertDialogTitle>
                              <AlertDialogDescription>
                                {t(
                                  `Möchten Sie die Ebene (${level.base.name}) unwiderruflich löschen?`,
                                )}
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>
                                {t("Abbruch")}
                              </AlertDialogCancel>
                              <AlertDialogAction
                                onClick={handleDelete}
                                className="bg-destructive hover:bg-destructive/90"
                              >
                                {t("Bestätigen")}
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      )}
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            onClick={() => setEditMode(false)}
                          >
                            <X className="h-5 w-5" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t("Abbrechen")}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <CollapsibleContent className="border-b">
          {availableAreas && availableAreas.length > 0 && (
            <>
              {availableAreas.map((area) => (
                <AreaSegmentCollapsible
                  key={area.base.id}
                  id={area.base.id}
                  level={level}
                  area={area}
                  isOpen={false}
                />
              ))}
            </>
          )}
          {(!availableAreas || availableAreas.length <= 0) && (
            <div className="p-4">
              {t(
                "Es sind noch keine Bereiche vorhanden. Bitte fügen Sie neue Bereiche hinzu.",
              )}
            </div>
          )}
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}

import { H4 } from "@/shared/components/ui/typography";
import { useEffect, useState } from "react";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { Button } from "@/shared/components/ui/button";
import { Label } from "@/shared/components/ui/label";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  useQpmCertificateListQuery,
  useQpmCustomerCertificateCreateMutation,
  useQpmCustomerCertificateDeleteMutation,
  useQpmCustomerCertificateListQuery,
} from "@/services/backend/qpm/certificates/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export type Certificate = {
  id: string;
  certificateAvailable: boolean;
  certType: string;
  name: string;
};

export type CertificateList = Certificate[];

export type CertificateComponentProps = {
  customerId: string;
  comments: string;
  onChange: (comment: string) => void;
};

export function CertificatesComponent({
  customerId,
  comments,
  onChange,
}: CertificateComponentProps) {
  const [selectedCertificates, setSelectedCertificates] =
    useState<CertificateList>([]);
  const [additionalComment, setAdditionalComment] = useState<string>(comments);
  const {
    data: qpmCertificateList,
    error: errorCertificates,
    isLoading: isLoadingCertificates,
  } = useQpmCertificateListQuery(null);

  const {
    data: customerCertificates,
    error: errorCustomerCertificates,
    isLoading: isLoadingCustomerCertificates,
  } = useQpmCustomerCertificateListQuery({ customerId });

  const [
    addCustomerCertificate,
    {
      error: errorAddCustomerCertificate,
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isLoading: isLoadingAddCustomerCertificate,
      reset: resetAddCustomerCertificate,
    },
  ] = useQpmCustomerCertificateCreateMutation();

  const [
    deleteCustomerCertificate,
    {
      error: errorDeleteCustomerCert,
      // TODO
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isLoading: isLoadingDeleteCustomerCert,
      reset: resetDeleteCustomerCert,
    },
  ] = useQpmCustomerCertificateDeleteMutation();

  useEffect(() => {
    const certs: Certificate[] = [];
    if (qpmCertificateList) {
      qpmCertificateList.certificates.forEach((cert) => {
        const certExists =
          (customerCertificates &&
            customerCertificates.certificates.some(
              (c) => c.certificateId === cert.id,
            )) ||
          false;
        certs.push({
          id: cert.id,
          certType: cert.type,
          name: cert.name,
          certificateAvailable: certExists,
        });
      });
    }
    setSelectedCertificates(certs);
  }, [qpmCertificateList, customerCertificates]);

  const handleClick = (cert: Certificate) => {
    const newCerts: CertificateList = selectedCertificates.map((c) =>
      c.id === cert.id
        ? { ...c, certificateAvailable: !c.certificateAvailable }
        : c,
    );

    const request = {
      customerId,
      certificateId: cert.id,
    };
    if (cert.certificateAvailable) {
      deleteCustomerCertificate(request);
    } else {
      addCustomerCertificate(request);
    }

    setSelectedCertificates(newCerts);
  };

  const { toast } = useToast();

  useEffect(() => {
    if (errorAddCustomerCertificate) {
      toast({
        ...parseRTKQueryError(errorAddCustomerCertificate),
      });
      resetAddCustomerCertificate();
    }
  }, [errorAddCustomerCertificate, resetAddCustomerCertificate, toast]);

  useEffect(() => {
    if (errorDeleteCustomerCert) {
      toast({
        ...parseRTKQueryError(errorDeleteCustomerCert),
      });
      resetDeleteCustomerCert();
    }
  }, [errorDeleteCustomerCert, resetDeleteCustomerCert, toast]);

  const onChangeCertificateText = (value: string) => {
    setAdditionalComment(value);
    onChange(value);
  };

  if (isLoadingCertificates || isLoadingCustomerCertificates) {
    return <CertificateSkeleton />;
  }

  if (errorCertificates) {
    return <RTKQueryErrorAlert error={errorCertificates} />;
  }

  if (errorCustomerCertificates) {
    return <RTKQueryErrorAlert error={errorCustomerCertificates} />;
  }

  return (
    <div className="flex flex-col gap-2 border-b py-2">
      <H4>Zertifikate</H4>
      <div className="flex flex-wrap justify-start gap-3">
        {selectedCertificates.map((cert) => (
          <div key={cert.id} className="flex items-center gap-1 p-1">
            <Checkbox
              checked={cert.certificateAvailable}
              onClick={() => handleClick(cert)}
            />
            <Button
              className="bg-transparent p-1 text-base text-black  hover:bg-transparent hover:text-primary"
              onClick={() => handleClick(cert)}
            >
              {cert.name}
            </Button>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap">
        <Label htmlFor="additionalCertificateText" className="mb-2">
          Sonstige
        </Label>
        <Textarea
          id="additionalCertificateText"
          placeholder="Sonstige"
          value={additionalComment}
          onChange={(e) => {
            onChangeCertificateText(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

function CertificateSkeleton() {
  return (
    <div className="flex flex-col gap-2 border-b py-2">
      <H4>Zertifikate</H4>
      <div className="flex flex-wrap justify-start gap-3">
        <Skeleton className="h-8 w-full" />
      </div>
      <div className="flex flex-wrap">
        <Label htmlFor="additionalCertificateText" className="mb-2">
          Sonstige
        </Label>
        <Textarea id="additionalCertificateText" placeholder="Sonstige" />
      </div>
    </div>
  );
}

import { AreaSelectorComboBox } from "@/routes/gemex/processes/qpm/shared/components/area-selector-component";
import { Area, AreaList } from "@/services/backend/qpm/buildings/types";
import {
  AddRequirementAreaRequest,
  Requirement,
} from "@/services/backend/qpm/requirements/types";
import { useQpmRequirementAreaAddMutation } from "@/services/backend/qpm/requirements/service";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useCallback, useEffect } from "react";
import { useToast } from "@/shared/components/ui/use-toast";

export type RequirementAreaSelectorProps = {
  requirement: Requirement;
  areas?: AreaList;
  levelId?: string;
};

export function RequirementAreaSelector({
  requirement,
  areas,
  levelId,
}: RequirementAreaSelectorProps) {
  const { toast } = useToast();
  const [
    selectRequirementArea,
    { error: errorRequirementAreaAdd, reset: resetRequirementAreaAdd },
  ] = useQpmRequirementAreaAddMutation();

  useEffect(() => {
    if (errorRequirementAreaAdd) {
      toast({
        ...parseRTKQueryError(errorRequirementAreaAdd),
        variant: "destructive",
      });
      resetRequirementAreaAdd();
    }
  }, [errorRequirementAreaAdd, resetRequirementAreaAdd, toast]);

  const handleAreaSelect = useCallback(
    (area: Area) => {
      if (area && areas) {
        const request: AddRequirementAreaRequest = {
          requirementId: requirement.id,
          areaId: area.base.id,
        };
        selectRequirementArea(request);
      }
    },
    [areas, requirement, selectRequirementArea],
  );

  return (
    <AreaSelectorComboBox
      areas={areas}
      onAreaSelect={handleAreaSelect}
      levelId={levelId}
      onAreaCreate={handleAreaSelect}
    />
  );
}

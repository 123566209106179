import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import { AuthProvider } from "@/shared/lib/authorization/auth-provider";
import { ActiveCompanyProvider } from "@/shared/lib/active-company/active-company-provider";
import { ActiveCompanyGuard } from "@/shared/lib/active-company/active-company-guard";
import App from "./App";
import { store } from "./store";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <ActiveCompanyProvider>
          <ActiveCompanyGuard>
            <App />
          </ActiveCompanyGuard>
        </ActiveCompanyProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
);

import { useEffect, useState } from "react";
import { useVbsTreatmentDeletePositionMutation } from "@/services/backend/vbs/treatments/service";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { Position } from "@/services/backend/vbs/treatments/position";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import t from "@/lang/lang";

export function DeletePositionDialog(props: {
  treatmentId: string;
  position: Position;
}) {
  const { treatmentId, position } = props;
  const [open, setOpen] = useState(false);
  const [deleteRequirement, { isLoading, error, isSuccess, reset }] =
    useVbsTreatmentDeletePositionMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const doDelete = () => {
    if (isLoading) {
      return;
    }
    deleteRequirement({
      id: treatmentId,
      positionId: position.id,
    });
  };

  const { setPersistenceState } = usePersistenceContext();
  useEffect(() => {
    if (isLoading) {
      setPersistenceState(PersistenceState.SAVING);
    } else if (error) {
      setPersistenceState(PersistenceState.ERROR);
    } else {
      setPersistenceState(PersistenceState.SAVED);
    }
  }, [isLoading, error, setPersistenceState]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t(`Position ${position.number} wirklich löschen?`)}
          </DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { Outlet } from "react-router-dom";
import {
  SideNavContainer,
  SideNavLink,
} from "@/routes/base-data/_components/layout/side-nav";
import {
  sortCompanies,
  useListCompanyQuery,
} from "@/services/backend/company/company";
import t from "../../lang/lang";

export default function BaseDataRoute() {
  const { data } = useListCompanyQuery({});

  const companies = sortCompanies(data?.companies ?? [], "name");

  return (
    <div className="mx-2 my-4 space-y-2">
      <div className="flex flex-col pt-2 sm:flex-row sm:gap-12 sm:pt-0">
        <SideNavContainer>
          <div className="mb-2 text-sm font-medium">
            {t("Dienstleistungen")}
          </div>
          <SideNavLink to="processes/qpm">QPM</SideNavLink>
          <SideNavLink to="processes/rlt">RLT</SideNavLink>
          <SideNavLink to="processes/vbs">VBS</SideNavLink>
          <div className="mb-2 mt-4 text-sm font-medium">{t("Nummern")}</div>
          <SideNavLink to="numbers/artefact">{t("Dokumente")}</SideNavLink>
          <SideNavLink to="numbers/process">{t("Vorgänge")}</SideNavLink>
          <div className="mb-2 mt-4 text-sm font-medium">
            {t("Unternehmen")}
          </div>
          <SideNavLink to="companies">{t("Allgemein")}</SideNavLink>
          {companies.map((company) => (
            <SideNavLink key={company.id} to={`companies/${company.id}`}>
              {company.shortName}
            </SideNavLink>
          ))}
          <div className="mb-2 mt-4 text-sm font-medium">
            {t("Niederlassungen")}
          </div>
          <SideNavLink to="branches">{t("Allgemein")}</SideNavLink>
          <div className="mb-2 mt-4 text-sm font-medium">{t("Kontakte")}</div>
          <SideNavLink to="contacts/contact-kind">
            {t("Kontaktarten")}
          </SideNavLink>
          <div className="mb-2 mt-4 text-sm font-medium">MS Dynamics</div>
          <SideNavLink to="dynamics/authentication">
            {t("Authentication")}
          </SideNavLink>
          <SideNavLink to="dynamics/matches">{t("Verknüpfungen")}</SideNavLink>
          <div className="mb-2 mt-4 text-sm font-medium">{t("Entra")}</div>
          <SideNavLink to="entra/entra-groups">
            {t("Entra Gruppen")}
          </SideNavLink>
        </SideNavContainer>
        <main className="flex-grow">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

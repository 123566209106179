import { isFetchBaseQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

/**
 * ValidationError is the validation error as
 * returned by the backend.
 */
export interface ValidationError {
  msg: string;
  errors: FieldErrors;
}

export function fieldErrors(error: unknown, field: string): string[] | null {
  if (!error) {
    return null;
  }

  if (!isValidationError(error)) {
    return null;
  }

  return error.errors[field] ?? null;
}

export function resetField(
  error: unknown,
  field: string,
): ValidationError | null {
  if (!error) {
    return null;
  }

  if (!isValidationError(error)) {
    return null;
  }

  return {
    ...error,
    errors: Object.fromEntries(
      Object.entries(error.errors).filter(([key]) => key !== field),
    ),
  };
}

export function isValidationError(error: unknown): error is ValidationError {
  // test if error is an object
  if (!error || typeof error !== "object") {
    return false;
  }

  // test if error has the required fields
  if (!("msg" in error) || !("errors" in error)) {
    return false;
  }

  // test if the fields are of the correct type
  if (typeof error.msg !== "string") {
    return false;
  }

  return isFieldErrors(error.errors);
}

export function rtkErrIsValidationError(
  error: unknown,
): error is { data: ValidationError } {
  if (!error) {
    return false;
  }

  if (!isFetchBaseQueryError(error)) {
    return false;
  }

  if (error.status !== 422) {
    return false;
  }

  return isValidationError(error.data);
}

interface FieldErrors {
  [field: string]: string[];
}

function isFieldErrors(errors: unknown): errors is FieldErrors {
  if (typeof errors !== "object" || errors === null) {
    return false;
  }

  return Object.entries(errors).every(
    ([, v]) => Array.isArray(v) && v.every((e) => typeof e === "string"),
  );
}

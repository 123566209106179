import { useEffect, useState } from "react";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  AssignWorkOrderRequest,
  useRltTreatmentAssignWorkSlipMutation,
} from "@/services/backend/rlt/treatments/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import t from "@/lang/lang";
import {
  useRltWorkOrderCreateMutation,
  useRltWorkOrderListQuery,
} from "@/services/backend/rlt/work-orders/service";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { v4 } from "uuid";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function AssignedWorkOrderCell({
  treatmentId,
  processId,
  positionId,
  workOrderId,
}: {
  treatmentId: string;
  processId: string;
  positionId: string;
  workOrderId: string;
}) {
  const [request, setRequest] = useState<AssignWorkOrderRequest>({
    id: treatmentId,
    positionId,
    workOrderId,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentAssignWorkSlipMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
    {
      toastError: true,
    },
  );

  return (
    <WorkOrderSelect
      processId={processId}
      workOrderId={request.workOrderId}
      onWorkOrderIdChange={(value) =>
        setRequest({ ...request, workOrderId: value })
      }
    />
  );
}

function WorkOrderSelect({
  processId,
  workOrderId,
  onWorkOrderIdChange,
}: {
  processId: string;
  workOrderId: string;
  onWorkOrderIdChange: (workOrderId: string) => void;
}) {
  const { data: list, error } = useRltWorkOrderListQuery({
    process: {
      active: true,
      values: [processId],
    },
    customer: { active: false, values: [] },
    immutable: { active: false, values: [] },
  });

  return (
    <Select value={workOrderId} onValueChange={onWorkOrderIdChange}>
      <SelectTrigger className="h-8 w-[280px]">
        <SelectValue placeholder={t("Arbeitsschein auswählen... ")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{t("Arbeitsscheine:")}</SelectLabel>
          <RTKQueryErrorAlert error={error} />
          {list?.workOrders
            .filter((workOrder) => !workOrder.immutable)
            .map((workOrder) => (
              <SelectItem key={workOrder.id} value={workOrder.id}>
                <ArtefactNumberById artefactId={workOrder.id} />
              </SelectItem>
            ))}
        </SelectGroup>
        <SelectSeparator />
        <SelectGroup className="flex justify-center px-2 py-2">
          <CreateWorkOrderButton processId={processId} />
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function CreateWorkOrderButton({ processId }: { processId: string }) {
  const [create, { isLoading, error, reset }] = useRltWorkOrderCreateMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    create({
      id: v4(),
      processId,
    });
  };

  return (
    <Button size="sm" className="w-full" variant="secondary" onClick={doCreate}>
      <Plus className="mr-2 h-4 w-4" />
      <span>{t("Arbeitsschein erstellen")}</span>
    </Button>
  );
}

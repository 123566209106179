import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Check, ChevronDown, Plus } from "lucide-react";
import t from "@/lang/lang";
import {
  PutRequest,
  useProcessesProcessPutMutation,
} from "@/services/backend/processes/process/service";
import {
  ProcessKind,
  ProcessKindList,
} from "@/services/backend/processes/process/kinds";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { useCustomersCustomerListQuery } from "@/services/backend/customers/service";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import { useListBranchQuery } from "@/services/backend/branch/branch";
import { v4 } from "uuid";
import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useToast } from "@/shared/components/ui/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Customer } from "@/services/backend/customers/customer";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import { useNavigate } from "react-router-dom";

export function NewProcessDialog() {
  const { companyId } = useActiveCompany();
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<PutRequest>({
    id: "",
    kind: ProcessKindList[0] as ProcessKind,
    topic: "",
    customerId: "",
    companyId,
    branchId: "",
    createdAt: "",
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    useProcessesProcessPutMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  if (isSuccess) {
    navigate(`${request.kind.toLowerCase()}/${request.id}`, {
      replace: true,
    });
    toast({
      title: t("Prozess erstellt"),
      variant: "success",
    });
    setOpen(false);
    reset();
  }

  if (error) {
    const err = parseRTKQueryError(error);
    toast({
      title: err?.title,
      description: err?.description,
      variant: "destructive",
    });
    setOpen(false);
    reset();
  }

  const onSubmit = () => {
    if (isLoading) {
      return;
    }
    put({
      ...request,
      id: v4(),
      createdAt: new Date().toISOString(),
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Neuer Vorgang")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Neuen Vorgang anlegen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Neue Vorgänge sollten hier ausschließlich angelegt werden, wenn es in MS Dynamics keine Verkaufschance gibt welche dem Vorgang entspricht. Ansonsten wird es unweigerlich zu Unklarheiten und Konflikten kommen.",
            )}
          </DialogDescription>
        </DialogHeader>
        <ProcessKindSelect
          processKind={request.kind}
          onProcessKindChange={(kind) => setRequest({ ...request, kind })}
        />
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="topic">{t("Thema")}</Label>
          <Input
            type="topic"
            id="topic"
            placeholder={t("Thema")}
            value={request.topic}
            onChange={(e) => setRequest({ ...request, topic: e.target.value })}
          />
        </div>
        <CustomerSelect
          customerId={request.customerId}
          onCustomerIdChange={(customerId) =>
            setRequest({ ...request, customerId })
          }
        />
        <BranchSelect
          branchId={request.branchId}
          onBranchIdChange={(branchId) => setRequest({ ...request, branchId })}
        />
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            {t("Abbrechen")}
          </Button>
          <Button onClick={onSubmit}>{t("Erstellen")}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function ProcessKindSelect(props: {
  processKind: ProcessKind;
  onProcessKindChange: (processKind: ProcessKind) => void;
}) {
  const { processKind, onProcessKindChange } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="grid w-full items-center gap-1.5">
      <Label>{t("Dienstleistung")}</Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="font-normal">
              {processKind || t("Dienstleistung auswählen")}
            </span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[450px] max-w-xs p-0 sm:max-w-full">
          <Command>
            <CommandInput placeholder={t("Dienstleistung suchen")} />
            <CommandEmpty>{t("Dienstleistung nicht gefunden")}</CommandEmpty>
            <ScrollArea className="max-h-[25vh]">
              <CommandGroup>
                {ProcessKindList.map((pk) => (
                  <CommandItem
                    key={pk}
                    value={pk}
                    onSelect={(currentValue) => {
                      onProcessKindChange(
                        (currentValue === processKind ? "" : pk) as ProcessKind,
                      );
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        processKind === pk ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {pk}
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function CustomerSelect(props: {
  customerId: string;
  onCustomerIdChange: (customerId: string) => void;
}) {
  const { companyId } = useActiveCompany();
  const { customerId, onCustomerIdChange } = props;
  const [open, setOpen] = useState(false);
  const {
    data: customerList,
    isLoading,
    error,
  } = useCustomersCustomerListQuery({
    companyId,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const list = customerList?.customers || [];

  return (
    <div
      className={cn(
        "grid w-full items-center gap-1.5",
        isLoading ? "animate-pulse" : "",
      )}
    >
      <Label>{t("Kunde")}</Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="font-normal">
              {customerName(customerId, list)}
            </span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[450px] max-w-xs p-0 sm:max-w-full">
          <Command>
            <CommandInput placeholder={t("Kunde suchen")} />
            <CommandEmpty>{t("Kunde nicht gefunden")}</CommandEmpty>
            <ScrollArea className="max-h-[25vh]">
              <CommandGroup>
                {[...list].sort(byName).map((customer) => (
                  <CommandItem
                    key={customer.id}
                    value={customer.name}
                    onSelect={(currentValue) => {
                      onCustomerIdChange(
                        currentValue === customerName(customerId, list)
                          ? ""
                          : customer.id,
                      );
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        customerId === customer.id
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {customer.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function byName(a: Customer, b: Customer) {
  return a.name.localeCompare(b.name);
}

function customerName(
  customerId: string,
  list: { id: string; name: string }[],
) {
  return list.find((c) => c.id === customerId)?.name || "Kunde auswählen";
}

function BranchSelect(props: {
  branchId: string;
  onBranchIdChange: (branchId: string) => void;
}) {
  const { companyId } = useActiveCompany();
  const { branchId, onBranchIdChange } = props;
  const [open, setOpen] = useState(false);
  const {
    data: branchList,
    isLoading,
    error,
  } = useListBranchQuery({
    companyId,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const list = branchList?.branches || [];

  return (
    <div
      className={cn(
        "grid w-full items-center gap-1.5",
        isLoading ? "animate-pulse" : "",
      )}
    >
      <Label>{t("Niederlassung")}</Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="font-normal">{branchName(branchId, list)}</span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[450px] max-w-xs p-0 sm:max-w-full">
          <Command>
            <CommandInput placeholder={t("Niederlassung suchen")} />
            <CommandEmpty>{t("Niederlassung nicht gefunden")}</CommandEmpty>
            <CommandGroup>
              {list.map((branch) => (
                <CommandItem
                  key={branch.id}
                  value={branch.name}
                  onSelect={(currentValue) => {
                    onBranchIdChange(
                      currentValue === branchName(branchId, list)
                        ? ""
                        : branch.id,
                    );
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      branchId === branch.id ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {branch.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function branchName(branchId: string, list: { id: string; name: string }[]) {
  return list.find((b) => b.id === branchId)?.name || "Niederlassung auswählen";
}

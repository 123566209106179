import { useParams } from "react-router-dom";
import { useVbsConfirmationShowQuery } from "@/services/backend/vbs/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ProcessRepresentativeCardById,
  RepresentativeCardSkeleton,
} from "@/shared/components/domain/representatives/representative-card";
import {
  filterErrorsWithPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function ConfirmationRepresentativeRoute() {
  const { confirmationId } = useParams();

  const {
    data: confirmation,
    isLoading,
    error,
  } = useVbsConfirmationShowQuery({
    id: confirmationId!,
  });

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  if (isLoading) {
    return <RepresentativeCardSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!confirmation) {
    return <div />;
  }

  return (
    <ProcessRepresentativeCardById
      id={confirmation.representativeId}
      fieldErrors={
        filterErrorsWithPrefix(notReadyError, "representative.")?.errors
      }
      resetFieldError={(field) =>
        resetNotReadyErrorField(`representative.${field}`)
      }
    />
  );
}

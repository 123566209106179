import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useParams } from "react-router-dom";
import { ProcessCustomerAddressCardById } from "@/shared/components/domain/addresses/customer-address-card";
import { ProcessServiceAddressCardById } from "@/shared/components/domain/addresses/service-address-card";
import { useRltOfferShowQuery } from "@/services/backend/rlt/offers/service";
import {
  filterErrorsWithPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";

export function OfferCustomerAddressRoute() {
  const { offerId } = useParams();
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const {
    data: offer,
    isLoading,
    error,
  } = useRltOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="animate-pulse p-6 text-muted-foreground">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <ProcessCustomerAddressCardById
        id={offer?.customerAddressId ?? ""}
        fieldErrors={
          filterErrorsWithPrefix(notReadyError, "customerAddress.")?.errors
        }
        resetFieldError={(field: string) =>
          resetNotReadyErrorField(`customerAddress.${field}`)
        }
      />
      <ProcessServiceAddressCardById
        id={offer?.serviceAddressId ?? ""}
        fieldErrors={
          filterErrorsWithPrefix(notReadyError, "serviceAddress.")?.errors
        }
        resetFieldError={(field: string) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
    </div>
  );
}
